<template>


  <main class="kb-main" id="kb-support">
    <LxpMobileHeader title="KB은행실무종합과정">
      <template v-slot:left>
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </template>
      <template v-slot:right>

      </template>
    </LxpMobileHeader>



    <div class="main-content main-component">
      <BpccInfo />
      <template v-if="tabMenu <= 0">

        <div class="intro-section" >

          <div class="kb-main" >
            <nav class="kb-nav-round-tabs"  style="padding:10px; margin-bottom: 0px;">
              <ul class="kb-nav-list">
                <li class="kb-nav-item">
                  <a href="#" class="kb-nav-link" :class="{ 'is-active' : menu===1 }" @click="menu=1"><span class="kb-nav-text">함께하는 은실종</span></a>
                </li>
                <li class="kb-nav-item">
                  <a href="#" class="kb-nav-link" :class="{ 'is-active' : menu===2 }" @click="menu=2"><span class="kb-nav-text">질의응답</span></a>
                </li>
<!--                <li class="kb-nav-item">-->
<!--                  <a href="#" class="kb-nav-link" :class="{ 'is-active' : menu===3 }" @click="menu=3"><span class="kb-nav-text">질의응답</span></a>-->
<!--                </li>-->
              </ul>
            </nav>

            <div v-if="menu===1" class="main-content min-component" style="padding-bottom: 20px;">
              <MobileBoardReadOnly
                  :board-id="boardId[0]"
              />
            </div>
            <div v-if="menu===2" class="main-content min-component" style="padding-bottom: 20px;">
              <MobileBoardReadOnly
                  :board-id="boardId[1]"
              />
            </div>
<!--            <div v-if="menu===3" class="main-content min-component" style="padding-bottom: 20px;">-->
<!--              <MobileBoardReadOnly-->
<!--                  :board-id="boardId[2]"-->
<!--              />-->
<!--            </div>-->
          </div>


        </div>

      </template>

      <!--    <HrdCloudMap2 v-model="cloudMapModal"/>-->

      <section id="kb-hrdcloud" class="hrdcloud section" style="padding-bottom:0px;">
        <template v-for="(item, idx) in mainList" :key="idx">
          <article class="content-section" >
            <header class="section-header"><h3 class="title">{{ item.title }}</h3>
              <div class="header-sub">
                <router-link :to="{name: 'HrdCloud', query:{sortTyCdDcd: '2073001',category1:'1',category2:'11' }}" class="more-link">전체보기</router-link>
              </div>
            </header>
            <template v-if="item.items && item.items.length > 0">
              <HrdCloudRow :items="item.items" :show-put="true" :session="session"/>
            </template>
            <template v-else-if="(item.items && item.items.length === 0) && !item.isLoading">
              <div class="search-container">
                <div class="result-empty">
                  <img src="../../../../assets/lxp/images/common/img_empty.png" alt="">
                  <p v-if="item.name === 'bpcc'" class="text">콘텐츠가 없습니다.</p>
                </div>
              </div>

            </template>
            <template v-else-if="item.isLoading">
              <LoadingDiv v-model="item.isLoading" />
            </template>

          </article>
        </template>
      </section>


    </div>

  </main>
</template>

<script>
import {goBack} from '@/assets/js/modules/common/common';
import {useStore} from 'vuex';
import {computed, reactive, ref} from 'vue';
import {
  closeToggles,
  getDcdOptions,
} from '@/assets/js/ui.init';
import {
  initProgressBar,
  timestampToDateFormat
} from '@/assets/js/util';
import { setMainItems} from '@/assets/js/modules/hrd/hrd-common';
import BpccInfo from '@/components/bpcc/BpccInfo';
import {
  ACT_GET_HRDCLOUD_BPCC_LIST
} from '@/store/modules/prep/prep';
import {
  banerExpsrLocCdDcds,
} from '@/assets/js/modules/baner/common-baner';
import {Pagination} from "swiper";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import MobileBoardReadOnly from '@/components/board/mobile/BoardReadOnly'
import LoadingDiv from "@/components/common/LoadingDiv";
import HrdCloudRow from "@/components/hrdcloud/HrdCloudRow";
const hrdLoading = ref(false);

export default {
  name: 'BpccMain',
  components: {
    MobileBoardReadOnly,
    // Swiper,
    // SwiperSlide,
    LxpMobileHeader,
    LoadingDiv,
    HrdCloudRow,
    BpccInfo,
  },
  setup() {
    initProgressBar();
    // const boardId = reactive([
    //   'bpcc-cert-chag',
    //   'bpcc-ques-board',
    //   'bpcc-qna-board'
    // ]);

    const boardId = reactive([
      'bpcc-toge-board',
      'bpcc-qna-2024',
    ]);

    const store = useStore();
    const lochref = ref(location.href);
    const bannerCode = ref(banerExpsrLocCdDcds.bpcc);
    const cloudMapModal = ref(false);
    const items = ref([]);
    const mainList = ref([
      { name: 'bpcc', title: 'KB은행실무종합과정 영상 보기\n', params: {}, isLoading: true, items:[], url:  `prep/${ACT_GET_HRDCLOUD_BPCC_LIST}` }
    ]);
    const isLoading = ref(false);

    const session = computed(() => store.state.auth.session);
    const sortTypeDcd = computed(() => getDcdOptions('2073'));

    // 순서를 변경할때 getMainList의 idx값도 같이 바꿔줘야함

    // const currentTs = ref(new Date().getTime());
    const menu = ref(1);
    const myLikeTotalCnt = ref('0');

    const hrds = ref([]);
    const categories = ref([]);
    const tubePaging = reactive({
      pageNo: 1,
      pageSize: 4,
      totalCount: 0
    });

    const params = ref({});
    const tabMenu = ref(0);
    const toggles = reactive({
      sortTyCdDcd: false,
      lvl1Toggle: false
    });
    // 지금 나에게 필요한 콘텐츠, 따끈따끈 신규 콘텐츠, 나의 최근 학습 콘텐츠, 최근 직무 분야 인기 콘텐츠, 최근 직무외 분야 인기 콘텐츠
    const getMainList = async () => {
      let sumgo = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_BPCC_LIST}`, {...tubePaging});
      setMainItems(mainList, sumgo, 0);
    };

    // getListFunc(`learn/${ACT_GET_SUMGO_LEARNING_LIVE_LIST}`, {}, items_live, null, () => {
    // });

    getMainList();

    return {
      isLoading, session, sortTypeDcd, hrds,   cloudMapModal, lochref,
      tabMenu, params, categories,
      mainList,
      toggles, closeToggles,
      modules: [Pagination],
      items,
      goBack,
      menu,
      myLikeTotalCnt,
      // board_first,
      // board_second,
      timestampToDateFormat,
      hrdLoading,
      bannerCode,
      boardId,

    }
  }
};
</script>